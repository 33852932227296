import React from 'react';

// Functions
import {markdownToHTML} from "../../utils/converter"

// Data
import mileData from "../../data/milestones.json"
import {useTranslation} from "react-i18next";

// -----------

function Milestones() {

    const {t} = useTranslation();

    return (
        <section id="milestones2" className="section-milestones full-width">
            <div className="content-wrapper milestones"/>
        </section>
    )
}

export default Milestones