import React from 'react';

import { useEffect, useState } from "react"

// Sections
import HomeSection from "./sections/Home"
import Service from "./sections/Service"
import Work from "./sections/work"
import Inspire from "./sections/inspire.js.bkp"
import About from "./sections/About"
import News from "./sections/News"
import Pricing from "./sections/Pricing"
import Skills from "./sections/Skills"
import Team from "./sections/team"
import Contact from "./sections/Contact"

// Components
import Loader from "../components/Loader"
import ClientsJs from "./sections/Clients.js"
import Divider from "./sections/Divider"
import Footer from "./sections/Footer"
import Milestones from "./sections/Milestones";
import Milestones2 from "./sections/Milestones2";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

// -------------------

function Home() {
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true)
  const [fadeOffLoader, setFadeOffLoader] = useState(false)

  useEffect(() => {
    const loaderTimer = setTimeout(handleLoad, 750)
    return () => {
      clearTimeout(loaderTimer)
    }
  }, [])

  const handleLoad = () => {
    setFadeOffLoader(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  return (
      <>
        {loading ? <Loader fadeOffLoader={fadeOffLoader} /> : <></>}

        <div id="content" className="site-content center-relative">
          <HomeSection />
          <Milestones/>
          <Work />
          <Divider />
          <About />
          {matchDownSM ? <Divider /> :  <Milestones2/>}
          <Service />
          <Divider />
          <Contact />


          {/**
          <InspireJs />
          <News />
          <Pricing />
          <Skills />


          <Divider />*/}
        </div>

        <Footer />

      </>
  )
}

export default Home