import React from 'react';

// Functions
import { markdownToHTML } from "../../utils/converter"

// Data
import mileData from "../../data/milestones.json"

// -----------

function Divider() {
    return (
        <section id="divider" className="section divider">
            <div className="clear"></div>
        </section>
    )
}

export default Divider