import React from "react"

function Loader({ fadeOffLoader }) {
    return (
        <div className={"preloader " + (fadeOffLoader ? "fadeOff" : "")}>
            <h1 className="loader-text">TÓNICA</h1>
        </div>
    )
}
export default Loader
