import React, { useState } from "react"
import { useTheme } from "@mui/material/styles";
// Plugins
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"

// UI Components
import WorkItem1 from "./items/WorkItem1"
import WorkItem2 from "./items/WorkItem2"
import WorkItem4 from "./items/WorkItem4"
import WorkItem3 from "./items/WorkItem3";

// Images
import portfolio1 from "./../../../assets/images/work/BioRAM.jpg"
import portfolio2 from "./../../../assets/images/work/BROT.png"
import portfolio4 from "./../../../assets/images/work/Tibet.png"
import portfolio3 from "./../../../assets/images/work/MSF.png"
import portfolio5 from "./../../../assets/images/work/BROT.png"
import portfolio6 from "./../../../assets/images/work/BioRAM.jpg"
import portfolio7 from "./../../../assets/images/work/Tibet.png"


// --> Icon Images
import backArrow from "./../../../assets/images/close-left-arrow.png"
import closeIcon from "./../../../assets/images/close.png"

// Functions
import { markdownToHTML } from "../../../utils/converter"

// Data
import portfolioData from "./../../../data/work.json"
import {useTranslation} from "react-i18next";
import WorkItem5 from "./items/WorkItem5";
import WorkItem6 from "./items/WorkItem6";
import WorkItem7 from "./items/WorkItem7";
import {useMediaQuery} from "@mui/material";

// --------------

function Work() {

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const {t} = useTranslation();

  const images = [portfolio1, portfolio2, portfolio3, portfolio4]

  // Portfolio item to be shown (change rendered different components in item folder)
  const [portfolioItem, setPortfolioItem] = useState(0)
  // Portfolio item to be shown as a popup
  const [openPortfolio, setOpenPortfolio] = useState(0)

  /**
   * Opening portfolio item that the user clicked
   *
   * @param num portfolio item to be open
   */
  const handleOpenItem = num => {
    const element = document.getElementById("portfolio-wrapper")
    if (element) {
      element.scrollIntoView()
    }

    setPortfolioItem(num)
  }

  /**
   * Close Opened portfolio item and show the portfolio grid images
   */
  const handlCloseItem = () => {
    setPortfolioItem(0)
  }

  /**
   * Open a popup of the item with the given number passed to the function
   *
   * @param num Pop up item number to be open
   */
  const handleOpenPopup = num => {
    setOpenPortfolio(num)
  }

  /**
   * Closed the opened items by reseting the {openPortfolio} variable to 0
   */
  const handleClosePopup = () => {
    setOpenPortfolio(0)
  }

  return (
      <section id="work" className="section page-split">
        <div className="section-wrapper block center-relative">
            <div className="content-wrapper">
              <p
                  className="entry-title"
                  dangerouslySetInnerHTML={{ __html: t("workTitle") }}
              />
              <h3
                  className="page-desc"
                  dangerouslySetInnerHTML={{ __html: t("workDesc") }}
              />
              <p>&nbsp;</p>

              <div id="work-wrapper" className="relative">
                {portfolioItem === 0 ? (
                    <>
                      <div id="work-grid">
                        {portfolioData.portfolioItems.map((item, i) => (
                            <div
                                key={"work-item-" + i}
                                className={`${item.className}`}
                            >
                              <div
                                  className="work-item-box"
                                  onClick={() => {
                                    // according to action type we wil fire the function
                                    if (
                                        item?.action?.type === "item" &&
                                        typeof item?.action?.number === "number"
                                    ) {
                                      handleOpenItem(item.action.number)
                                    } else if (
                                        item?.action?.type === "popup" &&
                                        typeof item?.action?.number === "number"
                                    ) {
                                      handleOpenPopup(item.action.number)
                                    }
                                  }}
                              >
                                <img src={images[i]} alt="" />
                                <div className="work-item-info">
                                  <p className="work-title">
                                    {t(item.description.text)}
                                  </p>
                                  <p className="work-desc">
                                    {t(item.description.caption)}
                                  </p>
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                    </>
                ) : (
                    // Portfolio items to be opened as a separate component
                    <div className={matchDownSM ? "work-load-content-holder" : "work-load-content-holder content-1170"}>
                      <div
                          className="close-icon"
                          role="button"
                          onClick={handlCloseItem}
                      >
                        <img src={backArrow} alt="back arrow" />
                      </div>
                      {portfolioItem === 1 ? (
                          <WorkItem1 />
                      ) : portfolioItem === 2 ? (
                          <WorkItem2 />
                      ) : portfolioItem === 3 ? (
                          <WorkItem3 />
                      ) : portfolioItem === 4 ? (
                          <WorkItem4 />
                      ) : (
                          <></>
                      )}
                    </div>
                )}
              </div>
            </div>
          <div className="clear"></div>

          {/* Popups portfolio items */}
          <Popup
              open={openPortfolio !== 0}
              closeOnDocumentClick
              onClose={handleClosePopup}
              modal
          >
            <div className="my-popup">
              <div
                  className="close-popup-btn"
                  role="button"
                  onClick={handleClosePopup}
              >
                <img src={closeIcon} alt="close icon" />
              </div>
              {openPortfolio === 1 ? (
                  <div className="popup-image-box">
                    <img src={portfolio4} alt="portfolio image" />
                  </div>
              ) : openPortfolio === 2 ? (
                  <div className="video-popup" onClick={close}>
                    <iframe
                        src="https://player.vimeo.com/video/199192931"
                        width="640"
                        allow="autoplay; fullscreen"
                    ></iframe>
                  </div>
              ) : (
                  <></>
              )}
            </div>
          </Popup>
        </div>
      </section>
  )
}

export default Work
