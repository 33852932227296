import React, {Fragment, useRef, useState} from "react"
import { styled } from '@mui/material/styles';
import emailjs from '@emailjs/browser';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
// Plugins
import axios from "axios"

// Images
import sec07Left from "./../../assets/images/sections/07_left.png"

// Functions
import {markdownToHTML} from "../../utils/converter"

// Data
import contactData from "../../data/contact.json"
import {useTranslation} from "react-i18next";
import PlayArrowIcon from "@mui/icons-material/PlayArrowOutlined";
import DesignIcon from "@mui/icons-material/DesignServicesOutlined";
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import {purple} from "@mui/material/colors";
import {CardContent, Stack, Typography} from "@mui/material";

const initialFormData = {
    "name": "",
    "email": "",
    "message": "",
    "gender": ""
}

const ColorButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText("#8FCEC8"),
    backgroundColor: "#8FCEC8",
    '&:hover': {
        backgroundColor: "#a6d8d3",
    },
    fontFamily: [
        'Quicksand', 'sans-serif',
    ].join(','),
    fontWeight: 700
}))

function Contact() {
    const form = useRef();
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const [formData, setFormData] = useState(initialFormData)
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    })

    /**
     * Change {formData} variable when user input data
     *
     * @param e change event in form inputs
     */
    const handleDataChange = e => {
        setFormData(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    /**
     * Handle the http request we sent to send our message (that user wrote)
     * and give message to the user to know what happened, is the message sent or not.
     *
     * @param ok if message has been sent or not
     * @param msg the message to be shown to the user
     */
    const handleServerResponse = (ok, msg) => {
        setServerState({
            submitting: false,
            status: {ok, msg}
        })
        if (ok) {
            setFormData(initialFormData)
        }
        setTimeout(() => {
            setServerState(prev => ({...prev, status: null}))
        }, 4000)
    }

    /**
     * Submitting message when user clock send button
     *
     * @param e form submit event
     */
    const sendEmail = e => {
        e.preventDefault()

        if (formData.gender === "") {
            // Submitting Form
            setServerState({submitting: true});
            setLoading(true);
            emailjs
                .sendForm('service_cn034y7', 'template_1llq5s5', form.current, {
                    publicKey: 'J5xW9e0hHkpkLtUTT',
                })
                .then(r => {
                    setLoading(false);
                    handleServerResponse(true, t("contactSendSuccesful"))
                })
                .catch(r => {
                    setLoading(false);
                    handleServerResponse(false, t("contactSendError"))
                })
        }


        /*axios({
          method: "post",
          url: contactData.formspreeEndpoint,
          data: formData
        })
            .then(r => {
              handleServerResponse(true, "Message Has Been Send")
            })
            .catch(r => {
              handleServerResponse(false, "Error occuars while sending")
            })*/
    }

    return (
        <section id="contact" className="section page-split">
            <div className="section-contact-form block content-1170 center-relative">
                <div className="content-wrapper">
                    <p
                        className="entry-title"
                        dangerouslySetInnerHTML={{__html: t("contactTitle")}}
                    />
                    <h3
                        className="page-desc"
                        dangerouslySetInnerHTML={{__html: t("contactDesc")}}
                    />
                    <p>&nbsp;</p>
                    <div className="contact-form">
                        <form ref={form} onSubmit={sendEmail}>
                            <p>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    disabled={loading}
                                    placeholder={t("contactName")}
                                    pattern="(?!.^\s$)[A-Za-z ]{3,}"
                                    required
                                    value={formData["your-name"]}
                                    onChange={handleDataChange}
                                />
                            </p>
                            <p>
                                <input
                                    id="contact-email"
                                    type="email"
                                    name="email"
                                    disabled={loading}
                                    placeholder={t("contactEmail")}
                                    pattern="!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
                                    required
                                    value={formData["your-email"]}
                                    onChange={handleDataChange}
                                />
                            </p>
                            <p>
                              <textarea
                                  id="message"
                                  name="message"
                                  disabled={loading}
                                  placeholder={t("contactMessage")}
                                  required
                                  value={formData["your-message"]}
                                  onChange={handleDataChange}
                              />
                            </p>
                            <input type="hidden" name="gender" value={formData["gender"]}/>
                            <p className="contact-submit-holder">
                                <ColorButton
                                    fullWidth
                                    type={"submit"}
                                    endIcon={<SendIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    {loading ? t("contactFormSending") : t("contactFormSend")}
                                </ColorButton>
                            </p>

                            {(!serverState.submitting && serverState.status?.msg) && (
                                <p className="respond-message">
                                    {serverState.status?.msg}
                                </p>
                            )}
                        </form>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>

            <div id="contact-info" className="section-contact-info block center-relative">
                <div className="content-wrapper content-1170 block center-relative">
                    <div className="content-wrapper-info ">
                    <Fragment key={"contactInfo1"}>
                        <div className="one_third">
                            <div className="contact-holder">
                                <div className="contact-img">
                                    <CallIcon sx={{fontSize: 35, color: '#8FCEC8'}}/>
                                </div>
                                <div className="contact-txt">
                                    <h4>{t("contactInfoTitle1")}</h4>
                                    <p>+54 9 11 3584-8609</p>
                                    <p>+34 661535741</p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    <Fragment key={"contactInfo2"}>
                        <div className="one_third">
                            <div className="contact-holder">
                                <div className="contact-img">
                                    <HomeIcon sx={{fontSize: 35, color: '#8FCEC8'}}/>
                                </div>
                                <div className="contact-txt">
                                    <h4>{t("contactInfoTitle2")}</h4>
                                    <p>Buenos Aires, Argentina</p>
                                    <p>Barcelona, España</p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    <Fragment key={"contactInfo3"}>
                        <div className="one_third last">
                            <div className="contact-holder">
                                <div className="contact-img">
                                    <EmailIcon sx={{fontSize: 35, color: '#8FCEC8'}}/>
                                </div>
                                <div className="contact-txt">
                                    <h4>{t("contactInfoTitle3")}</h4>
                                    <p>hola@tonica.com.ar</p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    </div>
                    <div className="clear"/>
                </div>
                <div className="clear"/>
            </div>

        </section>
    )
}

export default Contact