// Images
import helloImg from "./../../assets/images/home/hello.png"
import heroMain from "./../../assets/images/home/hero_main.jpg"
import {useTranslation} from "react-i18next";
import { useTheme } from "@mui/material/styles";
// Swiper Slider
import {Swiper, SwiperSlide} from "swiper/react"
import {EffectFade, Autoplay, Pagination, Navigation} from "swiper"

import "swiper/css"
import "swiper/css/pagination"


// Import Swiper styles
import {Link as ScrollLink} from "react-scroll";
import tonicaAire from "../../assets/images/home/tonica-aire.gif";
import tonicaFuego from "../../assets/images/home/tonica-fuego.gif";
import tonicaTierra from "../../assets/images/home/tonica-tierra.gif";

import tonicaAireSM from "../../assets/images/home/tonica-aire-sm.gif";
import tonicaFuegoSM from "../../assets/images/home/tonica-fuego-sm.gif";
import tonicaTierraSM from "../../assets/images/home/tonica-tierra-sm.gif";

import hello from "../../assets/images/home/hello.png";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";

// ---------------


function Home() {
    const { t } = useTranslation();
    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

    const renderSection = (image) => (
        <section
            id="home"
            className="section full-screen full-width"
            style={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundPosition: matchDownSM ? "bottom right" : "right"
            }}
        >
            <div className="section-wrapper block center-relative">
                <div className="content-wrapper home-text">
                    <p>{t('welcome')}</p>
                    <h2>{t('homeTitle')}</h2>
                    <h4>{t('homeInfo1')}</h4>
                    <h4>{t('homeInfo2')}</h4>
                </div>
            </div>
        </section>
    )

    const renderSwiper = (image1, image2, imag3) => (
        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={'fade'}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[EffectFade, Autoplay, Pagination, Navigation]}
        >
            <SwiperSlide>
                <ScrollLink smooth spy to={"aire"}>
                    {renderSection(image1)}
                </ScrollLink>
            </SwiperSlide>
            <SwiperSlide>
                <ScrollLink smooth spy to={"fuego"}>
                    {renderSection(image2)}
                </ScrollLink>
            </SwiperSlide>
            <SwiperSlide>
                <ScrollLink smooth spy to={"tierra"}>
                    {renderSection(imag3)}
                </ScrollLink>
            </SwiperSlide>
        </Swiper>
    )

    return (
        matchDownSM ? renderSwiper(tonicaAireSM, tonicaFuegoSM, tonicaTierraSM) : renderSwiper(tonicaAire, tonicaFuego, tonicaTierra)
    )
}

export default Home