import React from 'react';

// Images
import icon1 from "./../../../../assets/images/services/icon_preciese.png"
import icon3 from "./../../../../assets/images/services/icon_portfolio.png"
import icon2 from "./../../../../assets/images/services/icon_responsive.png"
import icon4 from "./../../../../assets/images/services/icon_support.png"
import {Grid} from "@mui/material";
import BioRAM1 from "../../../../assets/images/work/items/BioRAM1.png";
import BioRAM2 from "../../../../assets/images/work/items/BioRAM2.png";
import {useTranslation} from "react-i18next";
import BROT1 from "../../../../assets/images/work/items/BROT1.jpg";
import BROT2 from "../../../../assets/images/work/items/BROT2.jpg";
import BROT3 from "../../../../assets/images/work/items/BROT3.jpg";

// -------------------

function WorkItem3() {

    const {t} = useTranslation();

    return (
        <div className="work-content">
            <div className="info-text no-padding">
                Médicos Sin Fronteras
            </div>
            <div className="one_third">
                <iframe src="https://www.youtube.com/embed/vbSudyc4xlI" width="328" height="584" ></iframe>
            </div>
            <div className="two_third last">
                <div className="service-holder margin-0">
                    <div className="service-txt bottom-25">
                        {t("workInfo3")}
                    </div>
                    <div className="one">
                        <iframe src="https://www.youtube.com/embed/Nu5GTHVHUxI" width="584" height="422" ></iframe>
                    </div>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default WorkItem3
