import React from 'react';

// Images
import icon1 from "./../../../../assets/images/services/icon_preciese.png"
import icon3 from "./../../../../assets/images/services/icon_portfolio.png"
import icon2 from "./../../../../assets/images/services/icon_responsive.png"
import icon4 from "./../../../../assets/images/services/icon_support.png"
import {Grid} from "@mui/material";
import BioRAM1 from "../../../../assets/images/work/items/BioRAM1.png";
import BioRAM2 from "../../../../assets/images/work/items/BioRAM2.png";
import {useTranslation} from "react-i18next";

// -------------------

function WorkItem1() {

    const {t} = useTranslation();

    return (
        <div className="work-content">
            <div className="info-text">
                BioRAM
            </div>
            <div className="one_third">
                <iframe src="https://www.youtube.com/embed/WVQSuYdbg1o" width="328" height="584"/>
            </div>

            <div className="two_third last">
                <div className="service-holder margin-0">
                    <div className="service-txt bottom-25">
                        {t("workInfo1")}
                    </div>
                </div>
                <div className="one_half">
                    <img src={BioRAM1} alt=""/>
                </div>
                <div className="one_half last">
                    <img src={BioRAM2} alt=""/>
                </div>
            </div>

            {/**
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div className="video-popup">
                        <iframe src="https://www.youtube.com/embed/WVQSuYdbg1o" width="350" height="690"/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} alignContent={"center"}>
                    <img src={BioRAM1} alt=""/>
                </Grid>
                <Grid item xs={12} sm={4} alignContent={"center"}>
                    <img src={BioRAM2} alt=""/>
                </Grid>
            </Grid>*/}
            <p>&nbsp;</p>

            <div className="clear"/>
        </div>
    )
}

export default WorkItem1
