import React from 'react';

// Images
import BROT1 from "./../../../../assets/images/work/items/BROT1.jpg"
import BROT2 from "./../../../../assets/images/work/items/BROT2.jpg"
import BROT3 from "./../../../../assets/images/work/items/BROT3.jpg"

import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import BioRAM1 from "../../../../assets/images/work/items/BioRAM1.png";
import BioRAM2 from "../../../../assets/images/work/items/BioRAM2.png";

// -------------------

function WorkItem2() {

    const {t} = useTranslation();

    return (
        <div className="work-content">
            <div className="info-text no-padding">
                BROT
            </div>
            <div>
                <div className="service-holder">
                    <div className="service-txt">
                        {t("workInfo2")}
                    </div>
                </div>
            </div>
            <div className="clear"></div>
            <div className="one_third">
                <img src={BROT1} alt=""/>
            </div>
            <div className="one_third">
                <img src={BROT2} alt=""/>
            </div>
            <div className="one_third last">
                <img src={BROT3} alt=""/>
            </div>

            <div className="one">
                <div className="video-popup">
                    <iframe src="https://www.youtube.com/embed/danB0t9Nc30" width="1280" height="658" ></iframe>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default WorkItem2
