import React from 'react';

import {Fragment} from "react"

// Images
import sec01Left from "./../../assets/images/sections/01_left.png"
import servIcon1 from "./../../assets/images/services/icon_preciese.png"
import servIcon2 from "./../../assets/images/services/icon_support.png"
import servIcon3 from "./../../assets/images/services/icon_responsive.png"
import servIcon4 from "./../../assets/images/services/icon_community.png"
import servIcon5 from "./../../assets/images/services/icon_portfolio.png"
import servIcon6 from "./../../assets/images/services/icon_store.png"


import PlayArrowIcon from '@mui/icons-material/PlayArrowOutlined';
import DesignIcon from '@mui/icons-material/DesignServicesOutlined';
import LightbulbIcon from '@mui/icons-material/LightbulbOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideoOutlined';

// Functions
import {markdownToHTML} from "../../utils/converter"

// Data
import serviceData from "../../data/service.json"
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlaystation} from "@fortawesome/free-brands-svg-icons";

// -----------------------

function Service() {

    const {t, i18n} = useTranslation();

    const images = [
        <LightbulbIcon sx={{ fontSize: 35, color: '#8FCEC8' }}/>,
        <DesignIcon sx={{ fontSize: 35 , color: '#8FCEC8' }}/>,
        <OndemandVideoIcon sx={{ fontSize: 35 , color: '#8FCEC8' }}/>,
        <PlayArrowIcon sx={{ fontSize: 35 , color: '#8FCEC8' }}/>
    ]

    return (
        <section id="services" className="section page-split">
            <div className="section-wrapper block content-1170 center-relative">
                <div className="content-wrapper">
                    <p
                        className="entry-title"
                        dangerouslySetInnerHTML={{ __html: t("servicesTitle") }}
                    />
                    <h3
                        className="page-desc"
                        dangerouslySetInnerHTML={{ __html: t("servicesDesc") }}
                    />
                    <p>&nbsp;</p>

                    {serviceData.servicesBoxes.map((serv, i) => (
                        <Fragment key={"serv" + i}>
                            <div className={serv.className}>
                                <div className="service-holder">
                                    <div className="service-img">
                                        {images[i]}
                                    </div>
                                    <div className="service-txt">
                                        <h4>{t(serv.servTitle)}</h4>
                                        {t(serv.servDesc)}
                                    </div>
                                </div>
                            </div>
                            {serv.className.includes("last") && (
                                <div className="clear"/>
                            )}
                        </Fragment>
                    ))}
                </div>
                <div className="clear"/>
            </div>
        </section>
    )
}

export default Service