import React from 'react';

// Images
import Tibet1 from "./../../../../assets/images/work/items/Tibet1.jpg"
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import BROT1 from "../../../../assets/images/work/items/BROT1.jpg";
import BROT2 from "../../../../assets/images/work/items/BROT2.jpg";
import BROT3 from "../../../../assets/images/work/items/BROT3.jpg";

// -------------------

function WorkItem4() {

    const {t} = useTranslation();

    return (
        <div className="work-content">
            <div className="info-text no-padding">
                Tibet
            </div>
            <div>
                <div className="service-holder">
                    <div className="service-txt">
                        {t("workInfo4")}
                    </div>
                </div>
            </div>
            <div className="clear"></div>
            <div className="one_half">
                <div className="video-popup">
                    <iframe src="https://www.youtube.com/embed/A3UAMbg55mA" width="500" height="538"/>
                </div>
            </div>
            <div className="one_half last">
                <img src={Tibet1} alt=""/>
            </div>

            <div className="one">
                <div className="video-popup">
                    <iframe src="https://www.youtube.com/embed/HD1IDI39h90" width="1280" height="660" ></iframe>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default WorkItem4
