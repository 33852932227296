import React, { useState } from "react"

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Link as ScrollLink } from "react-scroll"
import { Link as RouterLink } from "react-router-dom"

import Select from '@mui/material/Select';

// Images
import logo from "./../assets/images/logo.png"

// Data
import navData from "./../data/navbar.json"
import {useTranslation} from "react-i18next";
import {FormControl, MenuItem} from "@mui/material";

function Navbar({ isLanding }) {
  const { t, i18n } = useTranslation();

  const [lngs] = useState({
    es: { nativeName: 'Español' },
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' },
    fr: { nativeName: 'Français' },
    it: { nativeName: 'Italiano' }
  });

  const [navActive, setNavActive] = useState(false)

  /**
   * Hiding navigation on clicking a nav link (important in mobie view)
   */
  const handleLinkClick = () => {
    setNavActive(false)
  }

  /**
   * Toggle menu on clicking on menu btn
   */
  const handleMenuBtnClick = () => {
    setNavActive(!navActive)
  }

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value)
  }

  return (
      <header className="header-holder">
        <div className="menu-wrapper center-relative relative">
          <div className="header-logo">
            <RouterLink to="/">
              <img src={logo} alt="Cardea" />
            </RouterLink>
          </div>

          <div className="toggle-holder">
            <div
                id="toggle"
                onClick={handleMenuBtnClick}
                className={navActive ? "on" : ""}
            >
              <div className="first-menu-line"/>
              <div className="second-menu-line"/>
              <div className="third-menu-line"/>
            </div>
          </div>

          <div className={navActive ? "menu-holder show" : "menu-holder"}>
            <nav id="header-main-menu">
              <ul className="main-menu sm sm-clean">
                {navData.navLinks.map((link, i) => (
                    <li key={"nav-" + i} style={{ cursor: "pointer" }}>
                      <ScrollLink
                          activeClass="active"
                          smooth
                          spy
                          to={link.to}
                          onClick={handleLinkClick}
                      >
                        {t(`${link.text}`)}
                      </ScrollLink>
                    </li>
                ))}
                <li>
                  {/**<FormControl variant="standard" sx={{ minWidth: 130 }} size="small">
                    <Select
                        input={<BootstrapInput />}
                        name="language"
                        //value={age}
                        //defaultValue={30}
                        //label="Idioma"
                        onChange={(e) => handleLanguageChange(e)}
                        //renderValue={(value) => `⚠️  - ${value}`}
                    >
                      {Object.keys(lngs).map((lng) => (
                          <MenuItem key={lng} value={lng}>{lngs[lng].nativeName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>*/}



                  <select name="language"
                          value={i18n.language}
                          style={{ cursor: "pointer" }}
                          onChange={(e) => handleLanguageChange(e)}>
                    {Object.keys(lngs).map((lng) => (
                        <option className="select-selected" key={lng} value={lng}>{lngs[lng].nativeName}</option>
                    ))}
                  </select>

                </li>
                {/* <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#work">Work</a>
              </li>
              <li>
                <a href="#inspire">InspireJs</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#news">News</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              <li>
                <a href="#skills">Skills</a>
              </li>
              <li>
                <a href="#team">Team</a>
              </li>
              <li>
                <a href="#clients">ClientsJs</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li> */}
              </ul>
            </nav>
          </div>
          <div className="clear"></div>
        </div>
      </header>
  )
}

export default Navbar