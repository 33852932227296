import React from 'react';

// Functions
import {markdownToHTML} from "../../utils/converter"

// Data
import mileData from "../../data/milestones.json"
import {useTranslation} from "react-i18next";

// -----------

function Milestones() {

    const {t} = useTranslation();

    return (
        <section id="milestones" className="section-milestones full-width">
            <div className="content-wrapper milestones">
                <h6>{t('milestonesTitle')}</h6>
                <h4>{t('milestonesInfo')}</h4>
                <div className="clear"></div>
            </div>
        </section>
    )
}

export default Milestones