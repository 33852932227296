import React from 'react';

import {Fragment, useState} from "react"

// Plugins
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"

// Images
import sec03Right from "./../../assets/images/sections/03_right.png"
import videoImg from "./../../assets/images/about/video_img.jpg"
import playBtn from "./../../assets/images/about/play_btn.png"
import closeIcon from "./../../assets/images/close.png"

// Functions and Hooks
import {markdownToHTML} from "../../utils/converter"

// Data
import aboutData from "../../data/about.json"
import {Box, Grid} from "@mui/material";
import BioRAM1 from "../../assets/images/work/items/BioRAM1.png";
import BioRAM2 from "../../assets/images/work/items/BioRAM2.png";
import {useTranslation} from "react-i18next";
import about from "../../assets/images/about/about.jpg";
import pricingData from "../../data/pricing.json";



import team1 from "../../assets/images/team/team1.jpg";
import team2 from "../../assets/images/team/team2.jpg";
import team3 from "../../assets/images/team/team3.jpg";

import {Link as RouterLink} from "react-router-dom";

// -------------

function About() {

    const {t, i18n} = useTranslation();

    const [openVideoPopup, setOpenVideoPopup] = useState(false)

    const handleOpenVideoPopup = () => {
        setOpenVideoPopup(true)
    }

    const handleCloseVideoPopup = () => {
        setOpenVideoPopup(false)
    }

    return (
        <section id="about" className="section page-split block center-relative">
            <div className="about-section-wrapper block center-relative">
                <div className="content-wrapper">
                    <div className="one_half margin-0">
                        <img src={about} alt="about image"/>
                    </div>
                    <div className="one_half last">
                        <p
                            className="entry-title about-left"
                            dangerouslySetInnerHTML={{__html: t("aboutTitle")}}
                        />
                        <h3
                            className="page-desc about-left"
                            dangerouslySetInnerHTML={{__html: t("aboutDesc")}}
                        />

                        <h6
                            className="page-info about-left"
                            dangerouslySetInnerHTML={{__html: t("aboutInfo1")}}
                        />
                        <h6
                            className="page-info about-left"
                            dangerouslySetInnerHTML={{__html: t("aboutInfo2")}}
                        />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            {/**
            <div className="about-team-content-wrapper block center-relative">
                <div className="content-wrapper content-1170 center-relative">
                    <p
                        className="entry-title"
                        dangerouslySetInnerHTML={{ __html: t("teamTitle") }}
                    />
                    <h3
                        className="page-desc"
                        dangerouslySetInnerHTML={{ __html: t("teamDesc") }}
                    />

                    <div className="one_third false" key={"about-team-1"}>
                        <div className="about-team-table">
                            <div className={`about-team-table-img`}>
                                <img src={team3} alt=""/>
                            </div>
                            <div className="about-team-table-detail">
                                <h6>Nombre</h6>
                                <p>founder</p>
                            </div>
                        </div>
                    </div>

                    <div className="one_third false " key={"about-team-2"}>
                        <div className="about-team-table">
                            <div className={`about-team-table-img`}>
                                <img src={team3} alt=""/>
                            </div>
                            <div className="about-team-table-detail">
                                <h6>Nombre</h6>
                                <p>founder</p>
                            </div>
                        </div>
                    </div>

                    <div className="one_third last" key={"about-team-3"}>
                        <div className="about-team-table">
                            <div className={`about-team-table-img`}>
                                <img src={team3} alt=""/>
                            </div>
                            <div className="about-team-table-detail">
                                <h6>Nombre</h6>
                                <p>founder</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="clear"></div>
            </div> */}

        </section>
    )
}

export default About