import React from 'react';

// UI Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Icons
import {
  faFacebookF,
  faTwitter,
  faBehance,
  faDribbble, faInstagram, faLinkedin, faYoutube
} from "@fortawesome/free-brands-svg-icons"

// Functions
import { markdownToHTML } from "../../utils/converter"

// Data
import footerData from "../../data/footer.json"
import logo from "../../assets/images/logo.png";

// -----

function Footer() {
  return (
      <footer className="footer">
        <div className="footer-content center-relative">
          <div className="footer-img">
            <img src={logo} alt=""/>
          </div>
          <div className="social-holder">
            <a href="https://www.instagram.com/tonicamg/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://linkedin.com/company/tonicamg" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.youtube.com/channel/UCl6CMZdkk6o46c8b8BJuLqQ" target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.behance.net/tonicamg" target="_blank">
              <FontAwesomeIcon icon={faBehance} />
            </a>
          </div>

          <div className="footer-copy-text">
              <a>© TÓNICA {new Date().getFullYear()}</a>
              <p>Hecho por Tónica, Argentina</p>
          </div>
        </div>
      </footer>
  )
}

export default Footer